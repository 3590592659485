<template>
  <div v-click-outside="clickedOutside" :class="['header-search', { active }]">
    <div :class="['header-search__return', { active }]" @click="$emit('close')">
      <span class="header-search__return-animation" />
    </div>

    <input
      ref="input"
      v-model="searching"
      type="search"
      name="q"
      autocomplete="off"
      autocapitalize="off"
      class="header-search__input"
      :placeholder="$t('header.search_placeholder')"
      @focus="openPopularSearch"
    />

    <popular-search v-show="popularSearchOpened" @change="clickedOutside" />

    <three-dots-loading v-show="isSearching" class="header-search__spinner" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ThreeDotsLoading from '~/components/loadings/three-dots'
import PopularSearch from '~/components/header/header-search/popular-search/PopularSearch.vue'
import clickOutside from '~/mixins/click-outside'

let timer

export default {
  name: 'HeaderSearch',
  components: {
    ThreeDotsLoading,
    PopularSearch,
  },
  mixins: [clickOutside],
  props: {
    active: Boolean,
  },
  data() {
    return {
      searching: this.$route.query.q || '',
      popularSearchOpened: false,
    }
  },
  computed: {
    ...mapGetters({
      isSearching: 'searcher/isLoading',
    }),
    popularSearches() {
      return this.$store.state.popularSearches?.popularSearches
    },
  },
  watch: {
    searching(query) {
      if (query.length > 1) {
        this.popularSearchOpened = false
        clearTimeout(timer)

        timer = setTimeout(() => {
          const newFullPath = `/search?q=${query}`
          if (this.$route.fullPath === newFullPath) return
          this.$store.dispatch('searcher/setLoading', true)
          this.$router.push(newFullPath)
        }, 800)
      }
    },
    $route(to, from) {
      if (to.name !== 'search') {
        this.searching = ''
        this.$emit('close')
      }
    },
  },
  methods: {
    openPopularSearch() {
      try {
        if (!this.popularSearches.length)
          this.$store.dispatch('popularSearches/fetchPopularSearches')

        this.popularSearchOpened = true
      } catch (err) {
        this.$displayTopMessage(this.$t('action_error'), {
          type: 'error',
        })
      }
    },
    clickedOutside() {
      this.popularSearchOpened = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/_utils.scss';

$search_height: 42px;

.header-search {
  display: flex;
  border: 1px solid $color_line;
  border-radius: 4px;
  align-items: center;
  padding: 0 $search_height/2 - 5px;
  height: $search_height;
  background-color: $color_line;
  text-align: left;
  position: relative;

  svg {
    width: 24px;
    fill: #828286;
    margin-top: 3px;
  }

  .header-search__spinner {
    margin-right: 5px;
  }

  .header-search__return {
    position: relative;
    margin: 0 3px 0 5px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease-in 0.1s;
    cursor: pointer;

    &.active {
      opacity: 1;
      .header-search__return-animation::before {
        transform: translate3d(-7px, 3px, 0) rotate(-45deg) scaleX(0.4);
      }
      .header-search__return-animation::after {
        transform: translate3d(-7px, -3px, 0) rotate(45deg) scaleX(0.4);
      }
    }

    .header-search__return-animation {
      height: 1px;
      width: 22px;
      background: black;
      position: absolute;
      left: 10px;
      top: 25px;
      z-index: -1;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        transition: transform 0.2s ease-in 0.1s;
        background-color: black;
      }

      &::before {
        top: -6px;
      }
      &::after {
        top: 6px;
      }
    }
  }

  .header-search__input {
    margin-left: 10px;
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 30px;
    padding: 0;
    font-size: 15px;
    border: 0;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &::-webkit-input-placeholder {
      color: darken($color_line, 20%);
    }

    &:focus {
      border: 0;
      outline: 0;
      box-shadow: none;
    }
  }
  .header-search__icon {
    font-size: 16px;
  }

  .header-search__backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    opacity: 0.5;
    z-index: 2;

    &.header-search__backdrop--open {
      display: block;
    }
  }
}

html .header-search {
  opacity: 0;
  visibility: hidden;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: 50px;
  margin-top: 0;
  padding: 0;
  transition: all 0.4s ease-in-out;
  border-radius: 0;
  border-color: -internal-light-dark-color(
    rgb(118, 118, 118),
    rgb(195, 195, 195)
  );

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .header-search__icon {
    display: none;
  }

  .header-search__input {
    margin: 0;
    font-size: 18px;
    height: 52px;
  }
}

@media (min-width: $screen_large) {
  html .header-search {
    height: 90px;
    border: 0;

    .header-search__input {
      font-size: 24px;
    }
  }
}
</style>
